<template>
    <div class="app-subscriber-sso-login-button" :class="{ isDisabled: isDisabled() }" @click.stop="startLoginWithSso()">
        <template v-if="!localIsLoading">
            <div class="app-subscriber-sso-login-button__title">{{ consts.ssoButtonText }}</div>
        </template>
        <template v-else>
            <font-awesome-icon :icon="['far', 'spinner']" pulse></font-awesome-icon>
        </template>
    </div>
</template>

<script>
import langConsts from '@/lang/en';
import { mapState } from 'vuex';

export default {
    name: 'AppSubscriberSsoLoginButton',
    props: {
        ssoLoginUrl: {
            type: String,
            required: false,
            default: undefined
        }
    },
    data() {
        return {
            consts: langConsts,
            localIsLoading: false
        };
    },
    computed: {
        ...mapState('login', ['isLoading'])
    },
    methods: {
        startLoginWithSso() {
            if (this.ssoLoginUrl) {
                this.localIsLoading = true;
                window.location.assign(this.ssoLoginUrl);
            }
        },
        isDisabled() {
            return this.ssoLoginUrl === undefined || this.localIsLoading;
        }
    }
};
</script>

<style lang="scss">
.app-subscriber-sso-login-button {
    align-items: center;
    background: $white;
    border-radius: 0.125rem;

    /* Popup */
    box-shadow: 0 0 0.375rem $nevada-lighter-transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
    height: 2.375rem;

    &.isDisabled {
        opacity: 0.65;
        pointer-events: none;
    }
    &__title {
        color: $nevada;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 0.875rem;
        text-align: center;
    }
}
</style>
